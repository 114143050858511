// material-ui
import { Theme, styled } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";

// project import
// import { OverridableComponent } from "@mui/material/OverridableComponent";
import { ReactNode } from "react";
import { layout } from "common/constants";

// ==============================|| HEADER - APP BAR STYLED ||============================== //

interface IAppBarStyled {
  theme: Theme;
  open: boolean;
  children: ReactNode;
}

const AppBarStyled = styled(AppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }: IAppBarStyled) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    boxShadow: "none",
    marginLeft: layout.drawerWidth,
    width: `calc(100% - ${layout.drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

export default AppBarStyled;
