import {
  Toolbar,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Box,
  AppBar,
  Typography,
  Drawer,
  IconButton,
} from "@mui/material";
import Icon from "@mui/material/Icon";
import { Menu } from "@mui/icons-material";
import { useState } from "react";
import { IMenuItems, menuItems } from "common/constants";
import { useNavigate } from "react-router-dom";
import { useTheme } from "@mui/material/styles";

interface Props {
  drawerWidth: number;
}

export const MenuDrawer = ({ drawerWidth }: Props) => {
  const [mobileOpen, setMobileOpen] = useState(true);
  const [selectedMenu, setSelectedMenu] = useState<string>("Dashboard");

  const navigate = useNavigate();
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleMenuClick = (name: string, url: string) => {
    navigate(url);
    setSelectedMenu(name);
  };

  const theme = useTheme();

  const textColor = "#fafafa";
  const iconSelectedColor = "lightblue";

  const listItem = (menu: IMenuItems, index: number) => {
    var isSelected = menu.title == selectedMenu;
    return (
      <ListItem key={index} disablePadding>
        <ListItemButton
          sx={{
            zIndex: 1201,
            pl: 3,
            py: 1,
            ...(mobileOpen && {
              "&:hover": {
                bgcolor: "primary.lighter",
              },
              "&.Mui-selected": {
                bgcolor: "primary.lighter",
                borderRight: `2px solid ${theme.palette.primary.main}`,
                color: iconSelectedColor,
                "&:hover": {
                  color: iconSelectedColor,
                  bgcolor: "primary.lighter",
                },
              },
            }),
          }}
          onClick={() => handleMenuClick(menu.title, menu.url)}
        >
          <ListItemIcon
            sx={{
              minWidth: 28,
              color: isSelected ? iconSelectedColor : textColor,
              ...(!mobileOpen && {
                borderRadius: 1.5,
                width: 36,
                height: 36,
                alignItems: "center",
                justifyContent: "center",
                "&:hover": {
                  bgcolor: "secondary.lighter",
                  color: "#888",
                },
              }),
              ...(!mobileOpen &&
                isSelected && {
                  "&:hover": {
                    bgcolor: "primary.lighter",
                    color: "#888",
                  },
                }),
            }}
          >
            <menu.icon
              style={{ fontSize: mobileOpen ? "1rem" : "1.25rem" }}
            ></menu.icon>
          </ListItemIcon>
          <ListItemText
            primary={
              <Typography
                variant="h6"
                sx={{ color: isSelected ? iconSelectedColor : textColor }}
              >
                {menu.title}
              </Typography>
            }
          ></ListItemText>
        </ListItemButton>
      </ListItem>
    );
  };

  const drawer = (
    <div id="testContainer-2">
      <Toolbar />
      <Divider />
      <List>
        {menuItems
          .filter((menu) => !menu.isAdmin)
          .map((menu, index) => listItem(menu, index))}
      </List>

      <List>
        {menuItems
          .filter((menu) => menu.isAdmin)
          .map((menu, index) => listItem(menu, index))}
      </List>
    </div>
  );

  return (
    <>
      <AppBar
        position="fixed"
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
          backgroundColor: "transparent",
          boxShadow: "none",
          borderBottom: "1px solid",
        }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerToggle}
            edge="start"
            sx={{ mr: 2, display: { sm: "none" }, color: "#07223D" }}
          >
            <Menu />
          </IconButton>
          <Typography variant="h6" noWrap component="div">
            Responsive drawer
          </Typography>
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          // container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              bgcolor: "#07223D",
            },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              bgcolor: "#07223D",
            },
          }}
          id="testContainer"
          open
        >
          {drawer}
        </Drawer>
      </Box>
    </>
  );
};

export default MenuDrawer;
