import {
  IContractContext,
  IContractContextProvider,
  IFacility,
  IService,
} from "@global-types/index";
import { createContext, useContext } from "react";

const ContractContext = createContext<IContractContext | null>(null);

export const ContractContextProvider = ({
  children,
}: IContractContextProvider) => {
  const getServices = (): IService[] => {
    const services: IService[] = [
      { id: 1, service: "Char Covers" },
      { id: 2, service: "Civil Marriage" },
      { id: 3, service: "Coloured Drapes" },
      { id: 3, service: "Drape Lights" },
      { id: 4, service: "Mid-Function Cleaning Charge" },
      { id: 5, service: "Old Car Park" },
      { id: 6, service: "Sound System" },
      { id: 7, service: "Table Clothes" },
    ];

    return services;
  };

  const getFacilities = (): IFacility[] => {
    const facilities: IFacility[] = [
      {
        id: 1,
        facility: "Annexe Hall",
      },
      {
        id: 2,
        facility: "Kitchen",
      },
      {
        id: 3,
        facility: "Main Hall",
      },
      {
        id: 4,
        facility: "Prayer Room",
      },
      {
        id: 5,
        facility: "Social Room",
      },
    ];

    return facilities;
  };

  const contextValue = {
    getFacilities,
    getServices,
  };

  return (
    <ContractContext.Provider value={contextValue}>
      {children}
    </ContractContext.Provider>
  );
};

export const useContracts = () =>
  useContext(ContractContext) as IContractContext;
