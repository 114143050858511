import { lazy } from "react";
import Loadable from "components/Loadable";
import { RouteObject } from "react-router-dom";
import DefaultLayout from "views/Layout/DefaultLayout/DefaultLayout";

const Login = Loadable(lazy(() => import("views/Auth/Login")));
const Register = Loadable(lazy(() => import("views/Auth/Register")));
const ForgotPassword = Loadable(
  lazy(() => import("views/Auth/ForgotPassword"))
);

const DefaultRoute: RouteObject = {
  path: "/",
  element: <DefaultLayout />,
  children: [
    {
      path: "/login",
      element: <Login />,
    },
    {
      path: "/register",
      element: <Register />,
    },
    {
      path: "/forgot-password",
      element: <ForgotPassword />,
    },
  ],
};

export default DefaultRoute;
