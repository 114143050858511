import { lazy } from "react";
import Loadable from "components/Loadable";
import { RouteObject } from "react-router-dom";
import MainLayout from "views/Layout/MainLayout/MainLayout";

const Dashboard = Loadable(lazy(() => import("views/Dashboard/Dashboard")));
const Contract = Loadable(lazy(() => import("views/Contract/Contract")));
const Reports = Loadable(lazy(() => import("views/Reports/Reports")));
const Customers = Loadable(lazy(() => import("views/Customers/Customers")));
const ListUsers = Loadable(lazy(() => import("views/Users/ListUsers")));
const ManageUsers = Loadable(lazy(() => import("views/Users/ManageUsers")));
const Settings = Loadable(lazy(() => import("views/Settings/Settings")));

const ManageContract = Loadable(
  lazy(() => import("views/Contract/ManageContract"))
);
const ManageCustomers = Loadable(
  lazy(() => import("views/Customers/ManageCustomers"))
);

const MainRoute: RouteObject = {
  path: "/",
  element: <MainLayout />,
  children: [
    {
      path: "/",
      element: <Dashboard />,
    },
    {
      path: "/dashboard",
      element: <Dashboard />,
    },
    {
      path: "/contract",
      element: <Contract />,
    },
    {
      path: "/reports",
      element: <Reports />,
    },
    {
      path: "/users",
      element: <ListUsers />,
    },
    {
      path: "/customers",
      element: <Customers />,
    },
    {
      path: "/manageUsers",
      element: <ManageUsers />,
    },
    {
      path: "/managecustomers",
      element: <ManageCustomers />,
    },
    {
      path: "/manage-contract",
      element: <ManageContract />,
    },
    {
      path: "/settings",
      element: <Settings />,
    },
  ],
};

export default MainRoute;
