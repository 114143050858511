import PropTypes from "prop-types";

// material-ui
import { useTheme } from "@mui/material/styles";
import {
  AppBar,
  AppBarOwnProps,
  // IconButton,
  Toolbar,
  useMediaQuery,
} from "@mui/material";

// project import
import AppBarStyled from "./AppBarStyled";
import HeaderContent from "./HeaderContent";

// assets
// import { MenuFoldOutlined, MenuUnfoldOutlined } from "@ant-design/icons";

// ==============================|| MAIN LAYOUT - HEADER ||============================== //

interface IHeader {
  open: boolean;
  handleDrawerToggle: () => void;
}

const Header = ({ open, handleDrawerToggle }: IHeader) => {
  const theme = useTheme();
  const matchDownMD = useMediaQuery(theme.breakpoints.down("lg"));

  // const iconBackColor = "grey.100";
  // const iconBackColorOpen = "grey.200";

  // common header
  const mainHeader = (
    <Toolbar>
      {/* <IconButton
        disableRipple
        aria-label="open drawer"
        onClick={handleDrawerToggle}
        edge="start"
        color="secondary"
        sx={{
          color: "text.primary",
          bgcolor: open ? iconBackColorOpen : iconBackColor,
          ml: { xs: 0, lg: -2 },
        }}
      >
        {!open ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
      </IconButton> */}
      <HeaderContent />
    </Toolbar>
  );

  // app-bar params
  const appBar: AppBarOwnProps = {
    position: "fixed",
    color: "inherit",
    sx: {
      borderBottom: `1px solid ${theme.palette.divider}`,
      // boxShadow: theme.customShadows.z1
    },
  };

  return (
    <>
      {!matchDownMD ? (
        <AppBarStyled open={open} {...appBar} theme={theme}>
          {mainHeader}
        </AppBarStyled>
      ) : (
        <AppBar {...appBar}>{mainHeader}</AppBar>
      )}
    </>
  );
};

Header.propTypes = {
  open: PropTypes.bool,
  handleDrawerToggle: PropTypes.func,
};

export default Header;
