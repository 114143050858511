import { Box, Breadcrumbs, CssBaseline, Toolbar } from "@mui/material";
import { useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { useAuth } from "store/context/auth-context";
import MenuDrawer from "./MenuDrawer";
import Header from "./Header";
import { layout } from "common/constants";

const MainLayout = () => {
  const { loggedIn } = useAuth();
  const navigate = useNavigate();

  const [open, setOpen] = useState<boolean>(true);
  const handleDrawerToggle = () => {
    setOpen(!open);
    //dispatch(openDrawer({ drawerOpen: !open }));
  };

  useEffect(() => {
    if (!loggedIn) {
      navigate("login?return=dashboard");
    }
  }, [loggedIn]);

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <Header open={open} handleDrawerToggle={handleDrawerToggle} />
      <MenuDrawer drawerWidth={layout.drawerWidth} />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          width: { sm: `calc(100% - ${layout.drawerWidth}px)` },
          maxHeight: "100%",
        }}
      >
        <Toolbar />
        <Outlet />
      </Box>
    </Box>
  );
};

export default MainLayout;
