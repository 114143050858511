import { IBookings, IDashboardContext } from "@global-types/index";
import { ReactNode, createContext, useContext } from "react";

const DashboardContext = createContext<IDashboardContext | null>(null);

export const DashboardContextProvider: any = ({
  children,
}: {
  children: ReactNode;
}) => {
  const getRecentBookings = (): IBookings[] => {
    return [
      {
        Number: "AAA-12",
        Customer: "William",
        EventDate: new Date(2024, 2, 2),
        Total: 500,
        Payment: 450,
      },
      {
        Number: "ABB-34",
        Customer: "Henry",
        EventDate: new Date(2024, 12, 24),
        Total: 700,
        Payment: 800,
      },
      {
        Number: "ABC-39",
        Customer: "Oliver",
        EventDate: new Date(2024, 6, 17),
        Total: 500,
        Payment: 450,
      },
      {
        Number: "AAA-12",
        Customer: "Jack",
        EventDate: new Date(2024, 7, 17),
        Total: 500,
        Payment: 450,
      },
      {
        Number: "AAA-12",
        Customer: "Noah",
        EventDate: new Date(2024, 11, 9),
        Total: 500,
        Payment: 450,
      },
    ];
  };

  const eventsThisWeek = 3;
  const bookingsThisWeek = 5;

  const contextValue: IDashboardContext = {
    getRecentBookings,
    eventsThisWeek,
    bookingsThisWeek,
  };

  return (
    <DashboardContext.Provider value={contextValue}>
      {children}
    </DashboardContext.Provider>
  );
};

export const UseDashboard = () =>
  useContext(DashboardContext) as IDashboardContext;
