import { 
    DashboardRounded, 
    TableViewRounded, 
    AddchartRounded, 
    GroupAddRounded, 
    AccountCircleRounded,
SettingsRounded,
MoneyOffRounded,
NotesRounded  } from "@mui/icons-material"

export interface IMenuItems {
    title: string,
    url: string,
    isAdmin?: boolean
    icon: typeof DashboardRounded
}

export const menuItems: IMenuItems[] = [
    {
        title: 'Dashboard',
        url: 'dashboard',
        icon: DashboardRounded
    },
    {
        title: 'Contract',
        url: 'contract',
        icon: TableViewRounded
    },
    {
        title: 'Customers',
        url: 'customers',
        icon: AccountCircleRounded
    },
    {
        title: 'Reports',
        url: 'reports',
        icon: AddchartRounded
    },
    {
        title: 'Users',
        url: 'users',
        isAdmin: true,
        icon: GroupAddRounded
    },
    {
        title: 'Pricing',
        url: 'pricing',
        isAdmin: true,
        icon: MoneyOffRounded
    },
    {
        title: 'Settings',
        url: 'settings',
        isAdmin: true,
        icon: SettingsRounded
    },
    {
        title: 'logs',
        url: 'logs',
        isAdmin: true,
        icon: NotesRounded
    },

]

export default menuItems;