import Grid from "@mui/material/Grid";
import Login from "views/Auth/Login";
import "./App.css";
import { Auth, DashboardContextProvider, useAuth } from "store";
import Routes from "routes";
import ThemeCustomization from "themes";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { ContractContextProvider } from "store/context/contract-context";

function App() {
  return (
    <ThemeCustomization>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Auth>
              <DashboardContextProvider>
                <ContractContextProvider>
                  <Routes />
                </ContractContextProvider>
              </DashboardContextProvider>
            </Auth>
          </Grid>
        </Grid>
      </LocalizationProvider>
    </ThemeCustomization>
  );
}

export default App;
