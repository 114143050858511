import { createContext, useContext, useState } from "react";
import { globalContentType, Props } from "@global-types/index";

const initialValue = {
  loggedIn: false,
  setLoggedIn: () => {},
};

const authContext = createContext<globalContentType>(initialValue);

export const Auth: any = ({ children }: Props) => {
  const auth: globalContentType = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
};

export const useAuth = () => useContext<globalContentType>(authContext);

const useProvideAuth = (): globalContentType => {
  const [loggedIn, setLoggedIn] = useState<boolean>(false);

  return {
    loggedIn,
    setLoggedIn,
  };
};
