import {
  Card,
  Grid,
  Typography,
  CardContent,
  CardHeader,
  Box,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { ReactNode } from "react";

interface IMainCard {
  title?: string;
  children?: ReactNode;
  elevation?: number;
  border?: boolean;
  content?: boolean;
  secondary?: ReactNode;
  additionalInfo?: string;
}

const MainCard = ({
  title,
  children,
  elevation,
  secondary,
  additionalInfo,
  border = true,
  content = true,
}: IMainCard) => {
  const theme = useTheme();

  return (
    <Card
      elevation={elevation || 0}
      sx={{
        border: border ? "1px solid" : "none",
        borderRadius: 2,
        borderColor: theme.palette.divider,
        height: "100%",
        overflow: "hidden",
        "& pre": {
          m: 0,
          p: "0px !important",
          fontFamily: theme.typography.fontFamily,
          fontSize: "0.75rem",
        },
        boxShadow:
          "rgba(0, 0, 0, 0.1) 0rem 0.25rem 0.375rem -0.0625rem, rgba(0, 0, 0, 0.06) 0rem 0.125rem 0.25rem -0.0625rem",
      }}
    >
      {title && (
        <Grid container>
          <Grid item xs={12} sm={4}>
            <CardHeader
              title={<Typography variant="h5">{title}</Typography>}
            ></CardHeader>
          </Grid>
          <Grid item xs={12} sm={8}>
            <Box
              sx={{
                textAlign: { sm: "end", xs: "start" },
                padding: 2,
                fontSize: 12,
              }}
            >
              {additionalInfo}
            </Box>
          </Grid>
        </Grid>
      )}
      <CardContent>
        <Grid container rowSpacing={0} columnSpacing={0}>
          <Grid item xs={12} sx={{ mb: -2.25 }}>
            {content && (
              <CardContent sx={{ p: "0 20px" }}>{children}</CardContent>
            )}
            {!content && children}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default MainCard;
